import { ApolloProvider } from "@apollo/react-hooks"
import { InMemoryCache, ApolloClient, HttpLink } from '@apollo/client';
import fetch from 'cross-fetch';
import { setContext } from "@apollo/client/link/context";
import React, { ReactElement } from "react"
import { API_EMPLOYEE_TOKEN } from "@src/utils/constant"
import gql from "graphql-tag";
import config from "../config"

const httpLink = new HttpLink({
  uri: config.GRAPHQL_SERVER_URL,
  fetch,
  fetchOptions: {
    credentials: "include",
  },
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(API_EMPLOYEE_TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "api-token": token || "",
    },
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getEmployeesByFilters: {
          keyArgs: false,
          merge(_existing = [], incoming) {
            return incoming;
          },
        },
      }
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

// default value in cache
cache.writeQuery({
  query: gql`
    query GetSelectedOrganization {
      selectedOrganization
    }
  `,
  data: {
    selectedOrganization: null,
  },
});

interface IProps {
  children: ReactElement
}

function AirApolloProvider({ children }: IProps) {
  return (
    <ApolloProvider client={client}>
      {React.cloneElement(children)}
    </ApolloProvider>
  )
}

export default AirApolloProvider
