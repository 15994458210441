import axios from "axios"
import config from "@src/config"

const apiUrl = `${config.API_SERVER_URL}/api/v1`
const generatorApiUrl = `${config.API_AI_GENERATOR_URL}`

export default axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: { "Content-Type": "multipart/form-data" },
})

export const generatorApi = axios.create({
  baseURL: generatorApiUrl,
  headers: { "Content-Type": "application/json" },
})
