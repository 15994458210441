import { ThemeProvider } from "@emotion/react"
import { useEffect, useState } from "react"
import Helmet from "react-helmet"
import navigateTo from "@src/utils/navigateTo"
import { useApolloClient, useQuery } from "@apollo/react-hooks"

import defaultConfig from "tailwindcss/defaultConfig"

/*  eslint-disable */
import { GET_CURRENT_USER, ME } from "@src/hooks/useUser"
import LoadingSpinner from "@src/components/ui/LoadingSpinner"
import {
  GET_SELECTED_ORGANIZATION,
  MY_ORGANIZATIONS,
} from "@src/hooks/useOrganization"
import DownLoadProgressModal, {
  DownLoadProgressModalData,
} from "@src/components/ui/DownLoadProgressModal"

import FontFace from "./FontFace"
import "./global.css"
import AppContext from "./AppContext"
import { reorderCSSInDevelopment } from "@src/utils/reorderCSSInDevelopment"

const resolveConfig: any = require("tailwindcss/lib/util/resolveConfig").default
const config = require("../../../tailwind.config.js")
const getConfigFunction = (config: any) => {
  return resolveConfig([config, defaultConfig])
}
const tailwindTheme = getConfigFunction(config)

const media = (key: string) => `
  @media (min-width: ${tailwindTheme.theme.screens[key]})
`

const theme = {
  ...tailwindTheme.theme,
  ...tailwindTheme,
  media,
}

const downloadingReportParam = "downloadingReport"

const Head = () => (
  <Helmet>
    <title>AirMason Admin</title>
    <link rel="preconnect" href="https://app.airmason.com" />
    <link rel="preconnect" href="https://trycom.s3.amazonaws.com" />

    <script async type="text/javascript">
      {`(function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://cdn.firstpromoter.com/fprom.js',t.onload=t.onreadystatechange=function(){var t=this.readyState;if(!t||"complete"==t||"loaded"==t)try{$FPROM.init("lkl3ype8",".airmason.com")}catch(t){}};var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)})();`}
    </script>
  </Helmet>
)

const getLocationPath = () => {
  return typeof window !== "undefined" ? window.location.pathname : "/"
}

const REQUIRED_LOGIN = {
  dashboard: RegExp(/^\/$/),
  team: RegExp(/^\/team\/?.*$/),
  handbook: RegExp(/^\/handbook\/?.*$/),
  folder: RegExp(/^\/folder\/?.*$/),
  profile: RegExp(/^\/profile\/?.*$/),
  integrations: RegExp(/^\/integrations\/?.*$/),
  internal: RegExp(/^\/internal\/?.*$/),
  create: RegExp(/^\/create\/?.*$/),
  switchOrganization: RegExp(/^\/switch-organization\/?.*$/),
}

function requiredLogin(): boolean {
  const pathName = getLocationPath()

  return (
    REQUIRED_LOGIN.dashboard.test(pathName) ||
    REQUIRED_LOGIN.team.test(pathName) ||
    REQUIRED_LOGIN.handbook.test(pathName) ||
    REQUIRED_LOGIN.folder.test(pathName) ||
    REQUIRED_LOGIN.profile.test(pathName) ||
    REQUIRED_LOGIN.integrations.test(pathName) ||
    REQUIRED_LOGIN.internal.test(pathName) ||
    REQUIRED_LOGIN.create.test(pathName) ||
    REQUIRED_LOGIN.switchOrganization.test(pathName)
  )
}

export const checkForRedirect = (reload: boolean, opts: any | undefined = {}) => {
  const params = new URLSearchParams(window.location.search)
  const portal = params.get("portal")
  const path = params.get("path")

  switch (portal) {
    case "editor":
      if (!path) {
        break
      }
      navigateTo.editHandbook(path)
      break;

    case "employee":
      navigateTo.employeeHandbook(path)
      break;

    case "admin":
      navigateTo.dashboard(opts, reload)
      break;

    default:
      navigateTo.dashboard(opts, reload)
  }
}

export default function Layout({ children }: { children: any }): any {
  const isRequiredLogin = requiredLogin()
  const [downLoadProgressModalData, setDownLoadProgressModalData] =
    useState<DownLoadProgressModalData | null>()
  // client
  const client = useApolloClient()

  // fetch user for all the pages
  const userResp = useQuery(ME, {
    fetchPolicy: "no-cache",
  })

  // user
  const user = userResp && userResp.data && userResp.data.me

  // fetch organizations only if user data is available
  const orgsResp = useQuery(MY_ORGANIZATIONS, {
    // skip: !user,
  })

  // organization
  const organizations =
    (orgsResp && orgsResp.data && orgsResp.data.getMyOrganizations) || []

  useEffect(() => {
    reorderCSSInDevelopment()
  }, [])

  // set selected organization
  useEffect(() => {
    // user or organizations don't exist
    if (!user || organizations.length === 0) {
      return
    }

    // select default organization
    let organization = organizations.find(
      (item: any) => item.id === user.selectedOrganization.id
    )

    if (!organization) {
      organization = organizations[0]
    }

    const userAccountType = user.organizationUserRelations[0].userAccountType
    const selectedOrganization = user.selectedOrganization

    // write data to apollo store
    client.writeQuery({
      query: GET_SELECTED_ORGANIZATION,
      data: {
        selectedOrganization: {
          ...selectedOrganization,
          id: parseFloat(selectedOrganization.id),
          userAccountType,
        },
      },
    })
  }, [user, organizations])

  useEffect(() => {
    if (!user) {
      return
    }

    const userAccountType = user.organizationUserRelations[0].userAccountType

    // write data to apollo store
    client.writeQuery({
      query: GET_CURRENT_USER,
      data: {
        currentUser: {
          ...user,
          id: parseFloat(user.id),
          userAccountType,
        },
      },
    })
  }, [user])

  // show loader while we fetch user or organizations
  if (
    !userResp.called ||
    userResp.loading ||
    !orgsResp.called ||
    orgsResp.loading
  ) {
    return <LoadingSpinner />
  }

  // when login is required but there is an error fetching user info
  // navigate to login page and still render the layout at the end of this function
  if (isRequiredLogin && (userResp.error || !user)) {
    // save the current url to local storage to redirect back after login
    const currentURL = window.location.pathname + window.location.search
    const isDownloadingReportParam = currentURL.includes(downloadingReportParam)
    if (isDownloadingReportParam) {
      navigateTo.login({
        state: { redirectUrl: currentURL },
      })
    } else {
      navigateTo.login()
    }
  }

  // user is already logged in but this is a login page
  // navigate to dashboard and still render the layout at the end of this function
  if (user) {
    checkForRedirect(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <FontFace />
      <Head />
      <AppContext.Provider
        value={{
          downLoadProgressModal: {
            showDownLoadProgressModal: setDownLoadProgressModalData,
            isShowing: !!downLoadProgressModalData,
          },
        }}
      >
        {children}
      </AppContext.Provider>

      {!!downLoadProgressModalData && (
        <DownLoadProgressModal
          isOpen={!!downLoadProgressModalData}
          onClose={() => setDownLoadProgressModalData(null)}
          data={downLoadProgressModalData}
        />
      )}
    </ThemeProvider>
  )
}
